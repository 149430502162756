import React from 'react'

import { Grid } from '@mui/material';

import Seo from "components/Seo";

import { Product } from "types";

import ProductCard from "components/ProductCard";

import Breadcrumbs from "../components/Breadcrumbs";

/**
 * Products
 */
const ProductTypes: React.FC<{ pageContext: {items: { node: Product }[], name: string}}> = (props) => {
  const { pageContext } = props;
  const { name, items } = pageContext;
  return (
    <>
      <Seo title={name}/>
      <Breadcrumbs
        dataBreadcrumb={
          [
            {
              id: name,
              name,
            },
            {
              id: 'produse',
              name: "Produse",
              to: '/products',
            },
          ]}
      />
      <Grid container spacing={3} sx={{mt:2}}>
        {
          items.map(({ node }) => {
            return (
              <Grid item  key={node.id} xs={12} sm={4} md={3}>
                <ProductCard product={node} />
              </Grid>
            )
          })}
      </Grid>
    </>
  )
}


export default ProductTypes;
